footer{
    font-size: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    background-color: #121212;
    opacity : 0.9;
}

footer a{
    color: white;
    text-decoration: none;
}