.toolNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    opacity : 0.9;
}

.logo{
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
    color: #FFF;
    font-weight: bold;
}

.ranking{
    text-decoration: none;
    cursor: pointer;
    background-color: #383838;
    padding: 5px 14px;
    color: #FFF;
    border-radius: 5px;
}

.opcoes-head a{
    margin-right: 8px;
}